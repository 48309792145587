import { ForgotApi } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers';  
export const state = {
  forgotData: {},
  forgotResponse: {},
};

export const mutations = {
  Set_Forgot_Content(state, dt) {
    state.forgotData = dt;
  },
  Set_Forgot_ServerResponse(state, dt) {
    state.forgotResponse = dt;
  }
};

export const actions = {
  // Get Forgot API Requests

  getForgotContents({ commit, dispatch }, guid) {
    dispatch("setLoading", true);
    ForgotApi.getForgotContent(guid)
      .then(res => {
        setTimeout(function () {
          dispatch("setLoading", false);
        }, 500);
        res.ApiHasError = false;
        commit("Set_Forgot_Content", res);
      })
      .catch(err => {
        dispatch("setLoading", false);
        let res = {
          ApiHasError: true,
          ValidationMessage: Helpers.getFullErrorResponse(err),
          PageContent: {},
          FormFields: {}
        };
        commit("Set_Forgot_Content", res);
        console.log(err);
      });
  },
  // Post Forgot API Requests 

  submitForgotData({ commit }, data) {
    ForgotApi.postForgotData(data)
      .then(res => {
        console.log({res});
        commit("Set_Forgot_ServerResponse", res);
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          Message: Helpers.getFullErrorResponse(err),
          PageContent: {},
          FormFields: {}
        };
        console.log(err);
        commit("Set_Forgot_ServerResponse", res);    
      });
  }
};
