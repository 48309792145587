let jwtTokenKey = "token";

export const JWTSetTokenMixin = {
  methods: {
    setToken(value) {
      localStorage.setItem(jwtTokenKey, value);
    }
  }
};

export const JWTGetTokenMixin = {
  methods: {
    getToken() {
      return localStorage.getItem(jwtTokenKey);
    }
  }
};

export const JWTRemoveTokenMixin = {
  methods: {
    removeToken() {
      localStorage.removeItem(jwtTokenKey);
    }
  }
};
