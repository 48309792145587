import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api`;
const axioService = new AxiosService(API_URL);

export var ExperianApi = {
  DoCanSearchAsync() {
    const url = `/addressverification/docansearchasync`;
    return axioService.get(url);
  },
  EmailVerification(email) {
    const url = `/emailverification/verify?email=${email}`;
    return axioService.get(url);
  },
  MobileVerification(phone) {
    const url = `/mobileverification/verify?phone=${phone}`;
    return axioService.get(url);
  },
  PortalMobileVerification(phone) {
    const url = `/mobileverification/portals?phone=${phone}`;
    return axioService.get(url);
  },
  GetAddress(moniker) {
    const url = `/addressverification/getaddress?moniker=${moniker}`;
    return axioService.get(url);
  },
  SearchAddress(searchAddr) {
    const url = `/addressverification/searchget?searchAddr=${searchAddr}`;
    return axioService.get(url);
  }
};
