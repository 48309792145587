import { FileGalleryService } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers';

export const state = {
  studentFileGalleryData: {},
};

export const mutations = {
  Set_Student_FileGallery_Content(state, dt) {
    state.studentFileGalleryData = dt;
  },
};

export const actions = {
  getFileGalleryData({ dispatch, commit }, datasource) {
    return FileGalleryService.getFileGalleryData(datasource)
      .then(res => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_Student_FileGallery_Content", res);
        } else {
          res.ApiHasError = true; 
          commit("Set_Student_FileGallery_Content", res);
          dispatch("setLoading", false);
        }
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue:{ValidationMessages: [Helpers.getErrorResponseMessage(err)]},
          err: err,
        };
        commit("Set_Student_FileGallery_Content", res);
      });
  },
};