import AxiosService from "./AxiosService";

const API_URL = process.env.VUE_APP_URL + "api/account/forgotapi";
const axioService = new AxiosService(API_URL);

export var ForgotApi = {
  getForgotContent(guID) {
    let queryString = window.location.search;
    queryString = queryString.replace("?", '&');
    const url = `/Forgot?dataSource=${guID + queryString}`;
    return axioService.get(url);
  },
  postForgotData(data) {
    const url = `/Forgot`;
    return axioService.post(url, data);
  },  
};