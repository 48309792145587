import { DonationService, LoginApi } from "../../services/ServiceWrapper";
import { Helpers } from "../helpers";

export const state = {
  donationDataSourceId: "",
  donationStep1Data: {},
  sponsorshipStep1Data: {},
  renewalStep1Data: {},
  donationStep2Data: {},
  donationStep3Data: {},
  donationStep4Data: {},
  paymentFrequency: "Once",
  isPaypalOffline: null,
  isCreditCardPaymentError: false,
  creditCardPaymentErrorMessage: "",
  donationAmountFormValues: {},
  donationPersonalDetailsFormValues: {},
  multiAddressValues: {},
  checkingFormBeforeSubmission: false,
  loginStep0Data: {},
  donationServerGeneralError: "",
  donationServerUnavailable: "",
  pledgeIncreaseAmountResponse: {}
};

export const mutations = {
  SET_DONATION_STEP1_CONTENTS(state, donationStep1Data) {
    state.donationStep1Data = donationStep1Data;
  },
  SET_SPONSORSHIP_STEP1_CONTENTS(state, sponsorshipStep1Data) {
    state.sponsorshipStep1Data = sponsorshipStep1Data;
  },
  SET_RENEWAL_STEP1_CONTENTS(state, renewalStep1Data) {
    state.renewalStep1Data = renewalStep1Data;
  },
  SET_DONATION_STEP2_CONTENTS(state, donationStep2Data) {
    state.donationStep2Data = donationStep2Data;
  },
  SET_DONATION_STEP3_CONTENTS(state, donationStep3Data) {
    state.donationStep3Data = donationStep3Data;
  },
  SET_DONATION_STEP4_CONTENTS(state, donationStep4Data) {
    state.donationStep4Data = donationStep4Data;
  },
  SET_DONATION_TYPE(state, type) {
    state.donationType = type;
  },
  SET_PAYMENT_FREQUENCY(state, type){
    state.paymentFrequency = type;
  },
  SET_DONATION_AMOUNT_FORM_VALUES(state, formFields) {
    state.donationAmountFormValues = formFields;
  },
  SET_DONATION_PERSONAL_DETAILS_FORM_VALUES(state, formFields) {
    state.donationPersonalDetailsFormValues = formFields;
  },
  SET_MULTI_ADDRESS_FORM_VALUES(state, formFields) {
    state.multiAddressValues = formFields;
  },
  SET_LOGIN_STEP0_CONTENTS(state, value) {
    state.loginStep0Data = value;
  },
  RESET_DONATION_SERVER_GENERAL_ERROR(state) {
    state.donationServerGeneralError = "";
  },
  SET_DONATION_SERVER_GENERAL_ERROR(state, value) {
    if(!value) {
      if(state.donationStep1Data && state.donationStep1Data.ValidationMessages) {
        value = state.donationStep1Data.ValidationMessages.GeneralErrorMessage; 
      } else if(state.sponsorshipStep1Data && state.sponsorshipStep1Data.ValidationMessages) {
        value = state.sponsorshipStep1Data.ValidationMessages.GeneralErrorMessage;
      } else if(state.renewalStep1Data && state.renewalStep1Data.ValidationMessages) {
        value = state.renewalStep1Data.ValidationMessages.GeneralErrorMessage;
      }
    }
    state.donationServerGeneralError = value;
  },
  SET_DONATION_SERVER_UNAVAILABLE(state) {
    state.donationServerUnavailable = Helpers.getDonationServerUnavailableMessage();
  },
  SET_PLEDGE_INCREASE_AMOUNT_RESPONSE(state, data) {
    state.pledgeIncreaseAmountResponse = data;
  },
};

export const actions = {
  getDonationsStep1Contents({ commit, dispatch }, params) {
    DonationService.setFormAPI("donationform");
    DonationService.setFormType("Donation");

    state.donationDataSourceId = params;

    DonationService.getDonationStep1(params)
      .then(response => {
        if (!params.quickDonate){
          dispatch("getDonationsStep2Contents", params);
        }
        commit("SET_DONATION_STEP1_CONTENTS", response);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_UNAVAILABLE");
      });
  },
  getSponsorshipStep1Contents({ commit, dispatch }, params) {
    DonationService.setFormAPI("sponsorshipform");
    DonationService.setFormType("ChildSponsorship");

    state.donationDataSourceId = params;
    
    DonationService.getSponsorshipStep1(params)
      .then(response => {
        dispatch("getDonationsStep2Contents", params);
        commit("SET_SPONSORSHIP_STEP1_CONTENTS", response);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_UNAVAILABLE");
      });
  },
  getRenewalStep1Contents({ commit, dispatch }, params) {
      DonationService.setFormAPI("renewalform");
      DonationService.setFormType("Renewal");

      state.donationDataSourceId = params;

      DonationService.getRenewalStep1(params)
        .then(response => {
            //check for participantId on query string
            //we only want to select the students from sessionStorage if there is no student that has been specifically selected
            if(window.location.search.indexOf('participantId=') === -1) {
                let selectedStudentsJson = sessionStorage.getItem('RENEW_SELECTED_STUDENTS');
                if(selectedStudentsJson && response.FormValues.SponsoredChildForRenewals) {
                    let selectedStudents = JSON.parse(selectedStudentsJson);

                    for(var i = 0; i < response.FormValues.SponsoredChildForRenewals.length; i++) {
                        let student = response.FormValues.SponsoredChildForRenewals[i];

                        if(selectedStudents[student.ParticipantId + '_' + student.AllocationId]) {
                            student.Selected = true;
                        } else {
                            student.Selected = false;
                        }
                    }
                }
            }
        
            dispatch("getDonationsStep2Contents", params);
            commit("SET_RENEWAL_STEP1_CONTENTS", response);
        })
        .catch(() => {
            commit("SET_DONATION_SERVER_UNAVAILABLE");
        });
  },
  getDonationsStep2Contents({ commit, dispatch }, params) {
    DonationService.getDonationStep2(params)
      .then(response => {
        dispatch("getDonationsStep3Contents", params);
        dispatch("getDonationsStep4Contents", params);
        dispatch("getLoginStep0ContentsModal", params);
        commit("SET_DONATION_STEP2_CONTENTS", response);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
      });
  },
  getLoginStep0ContentsModal({ commit }, params) {
    LoginApi.GetContentModal(params)
      .then(res => {
        commit("SET_LOGIN_STEP0_CONTENTS", res);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
      });
  },
  getDonationsStep3Contents({ commit }, params) {
    DonationService.getDonationStep3(params)
      .then(response => {
        commit("SET_DONATION_STEP3_CONTENTS", response);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
      });
  },
  getDonationsStep4Contents({ commit }, params) {
    DonationService.getDonationStep4(params)
      .then(response => {
        commit("SET_DONATION_STEP4_CONTENTS", response);
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
      });
  },
  setDonationType({ commit }, type) {
    commit("SET_DONATION_TYPE", type);
  },
  setPaymentFrequency({ commit }, type) {
    commit("SET_PAYMENT_FREQUENCY", type);
  },
  setDonationAmountFormValues({ commit }, info) {
    var formValues = info;
    commit("SET_DONATION_AMOUNT_FORM_VALUES", formValues);
  },
  setDonationPersonalDetailsFormValues({ commit }, formValues) {
    commit("SET_DONATION_PERSONAL_DETAILS_FORM_VALUES", formValues);
  },
  setMultiAddressFormValues({ commit }, formValues) {
    commit("SET_MULTI_ADDRESS_FORM_VALUES", formValues);
  },
// Possible Tech Debt 
  setCreditCardModalInfo({ state, dispatch }) {
    let data = state.donationStep4Data.PageContent || state.sponsorshipStep4Data.PageContent || state.renewalStep4Data.PageContent;
    if(data) {
      dispatch("setLoadingHeader", data.ProcessingHeading);
      dispatch("setLoadingMessage", data.ProcessingText);
      dispatch("setLoadingImage", data.ProcessingLogoImageSrc);
    }
  },
  // Refactoring Required
  submitForm({ state, dispatch, commit }, extraData) {
    let data = Helpers.constructDataForCreateTransaction(state, true, extraData);

    dispatch("setLoading", true);
    dispatch("setCreditCardModalInfo");
    commit("RESET_DONATION_SERVER_GENERAL_ERROR");

    state.isCreditCardPaymentError = false;

    DonationService.submitCreditCardPayment(data, state.donationDataSourceId)
      .then(res => {

        if (res.IsSuccess) {
          window.location.href = res.ResultValue.RedirectUrl;
        } else {
          state.isCreditCardPaymentError = true;
          state.creditCardPaymentErrorMessage = res.Message;
          dispatch("setLoading", false);
        }
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
        dispatch("setLoading", false);
      });
  },

  setIsCreditCardPaymentError({ state }, isCreditCardPaymentError) {
    state.isCreditCardPaymentError = isCreditCardPaymentError;
  },
  setCreditCardPaymentErrorMessage({ state }, message) {
    try {
      if (message.toLocaleLowerCase() == "unknown error"){
        state.creditCardPaymentErrorMessage = state.donationStep1Data.ValidationMessages.DonationCreditCardOfflineErrorMessage;
      }
      else {
        state.creditCardPaymentErrorMessage = state.donationStep3Data.ValidationMessages.PaymentFailed;
      }
    }
    catch {
      // unable to get the step 1 message, have to reset it to the same message now.
      state.creditCardPaymentErrorMessage = message;
    }
  },
  submitPaypal({ dispatch, commit, state }, modalInfo) {
    let data = Helpers.constructDataForCreateTransaction(state, false);

    dispatch("setLoadingHeader", modalInfo.Header);
    dispatch("setLoadingMessage", modalInfo.Message);
    dispatch("setLoadingImage", modalInfo.Image);
    dispatch("setLoading", true);
    commit("RESET_DONATION_SERVER_GENERAL_ERROR");

    DonationService.submitPaypal(data, state.donationDataSourceId)
      .then(res => {
        if (res.IsSuccess === false) {
          state.isPaypalOffline = res;
          dispatch("setLoading", false);
        } else {
          window.location.href = res.ResultValue.ApiUrl;
        }
      })
      .catch(() => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
        dispatch("setLoading", false);
      });
  },
  submitUpsell({ dispatch, commit, state }, modalInfo) {
    let data = Helpers.constructDataForCreateTransaction(state, false);

    dispatch("setLoadingHeader", modalInfo.Header);
    dispatch("setLoadingMessage", modalInfo.Message);
    dispatch("setLoadingImage", modalInfo.Image);
    dispatch("setLoading", true);
    commit("RESET_DONATION_SERVER_GENERAL_ERROR");

    DonationService.submitUpsell(data, state.donationDataSourceId)
      .then(res => {
        if (res.IsSuccess === false) {
          state.isPaypalOffline = true;
          dispatch("setLoading", false);
        } else {
          window.location.href = res.ResultValue.RedirectUrl;
        }
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          IsSuccess: false,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] },
        };
        commit("SET_PLEDGE_INCREASE_AMOUNT_RESPONSE", res);
        dispatch("setLoading", false);
      });
  }
};

export const getters = {
  summaryDonationDetails: state => {
    return state.donationAmountFormValues;
  },
  isPaypalOffline: state => {
    return state.isPaypalOffline;
  },
  isCreditCardPaymentError: state => {
    return state.isCreditCardPaymentError;
  },
  getCreditCardPaymentErrorMessage: state => {
    return state.creditCardPaymentErrorMessage;
  },
};
