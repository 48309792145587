import axios from "axios";
import { JWTGetTokenMixin } from "../mixins/JsonWebTokenMixin";

export default class AxiosService {

    constructor(baseURL) {
        let axiosConfig = {
            baseURL: baseURL,
            headers: {
                "Content-Type": "application/json",
            },
        };
        let verificationToken = this.getVerificationToken();
        if (verificationToken) {
            axiosConfig.headers["__RequestVerificationToken"] = verificationToken;
        }
        this.httpService = axios.create(axiosConfig);
    }

    getVerificationToken() {
        let RequestVerificationTokenElements = document.getElementsByName(
            "__RequestVerificationToken"
        );
        if (
            RequestVerificationTokenElements &&
            RequestVerificationTokenElements.length > 0
        ) {
            return RequestVerificationTokenElements[0].value;
        }
        return;
    }

    getBearerToken() {
        let bToken = JWTGetTokenMixin.methods.getToken();
        if (bToken) {
            let header = {
                headers: {
                    Authorization: 'Bearer ' + bToken
                }
            };
            return header;
        }
        return;
    }

    get(url, passToken) {
        return this.getPromise(url, passToken)
            .then(response => response.data);
    }

    post(url, data, passToken) {
        return this.postPromise(url, data, passToken)
            .then(response => response.data);
    }

    getPromise(url, passToken) {
        if (passToken) {
            let header = this.getBearerToken();
            if (header) {
                return this.httpService.get(url, header)
                    .then(response => response.data);
            }
        }
        else {
            return this.httpService.get(url);
        }
    }

    postPromise(url, data, passToken) {
        if (passToken) {
            let header = this.getBearerToken();
            if (header) {
                return this.httpService.post(url, data, header);
            }
        }
        else {
            return this.httpService.post(url, data);
        }

    }
}

