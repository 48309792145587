import AxiosService from "./AxiosService";

var formAPI = "";
var formType = "";
const API_URL = `${process.env.VUE_APP_URL}api/`;
// const API_URL = `https://cf9c646a-ad27-4a7f-973f-f2ecfd1c66a4.mock.pstmn.io`;
const axioService = new AxiosService(API_URL);

export var DonationService = {
  setFormAPI(url){
    formAPI = url;
  },
  setFormType(type){
    formType = type;
  },
  getDonationStep1(params) {
    let queryString = window.location.search;
    queryString = queryString.replace("?", '&');
    const url = `${formAPI}/getcontentstep1?datasource=${params.datasource + queryString}`;
    return axioService.get(url);
  },
  getSponsorshipStep1(params) {
    let queryString = window.location.search;
    queryString = queryString.replace("?", '&');
    const url = `${formAPI}/getcontentstep1?datasource=${params.datasource + queryString}`;
    return axioService.get(url);
  },
  getRenewalStep1(params) {
    let queryString = window.location.search;
    queryString = queryString.replace("?", '&');
    const url = `${formAPI}/getcontentstep1?datasource=${params.datasource + queryString}`;
    return axioService.get(url);
  },
  getDonationStep2(params) {
    let queryString = window.location.search;
    queryString = queryString.replace("?", '&');
    const url = `${formAPI}/getcontentstep2?datasource=${params.datasource + queryString}`;
    return axioService.get(url);
  },
  getDonationStep3(params) {
    const url = `${formAPI}/getcontentstep3?datasource=${params.datasource}`;
    return axioService.get(url);
  },
  getDonationStep4(params) {
    const url = `${formAPI}/getcontentstep4?datasource=${params.datasource}`;
    return axioService.get(url);
  },
  submitPaypal(data, params) {
    data.DonationFormId = params.datasource;    
    data.formType = formType;

    const url = `${formAPI}/createtransaction`;
    return axioService.post(url, data);
  },
  submitUpsell(data, params) {
    data.formType = formType;
    data.DonationFormId = params.datasource;

    const url = `${formAPI}/createtransaction`;
    return axioService.post(url, data);
  },
  submitCreditCardPayment(data, params) {
    data.DonationFormId = params.datasource;
    data.formType = formType;

    const url = `${formAPI}/createtransaction`;
    return axioService.post(url, data);
  }
};

export var DonationServiceMixin = {
  methods: {
    creditCardSubmit() {
      this.submitDonateAmount("Credit Card");
    },
    paypalSubmit() {
      this.submitDonateAmount("Paypal");
    },
    upsellSubmit() {
      this.submitDonateAmount("Upsell");
    },    
    touch() {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.setFormValuesInStore("Credit Card");
        return true;
      }
      return false;
    },
    submitCreditCardAllSteps() {
      console.log("submitCreditCardAllSteps");
      var step1Valid = this.touch();
      var step2Valid = this.$refs.step2ComponentRef.touch();
      var step3ValidPromise = this.$refs.step3ComponentRef.touch();

      var self = this;
      step3ValidPromise.then((step3Valid) => {
        if(step1Valid && step2Valid && step3Valid) {
          this.$refs.step3ComponentRef.submitFrame().then((step3Data) => {
            console.log(step3Data);
            grecaptcha.ready(() => { // eslint-disable-line
              grecaptcha.execute(self.donationStep3Data.PageContent.RecaptchaSiteKey, {action: 'donation'}).then((recaptchaToken) => { // eslint-disable-line
                  this.$store.dispatch("submitForm", {
                    payframeToken: step3Data.payframeToken,
                    payframeKey: step3Data.payframeKey,
                    recaptchaToken: recaptchaToken,
                    cardName: step3Data.cardName
                  });
              });
            });
          }).catch(() => {
            console.log("something went wrong");
            //payframe submit failed, error handling already done by submitFrame()
          });
        } else {
          if(step1Valid && step2Valid && !step3Valid) {
            this.$refs.step3ComponentRef.scrollToError();
          } else {
            this.$nextTick(function() {
              this.focusFirstErrorStatus();
            });
          }
        }
      });
    },       
  },
  watch: {
    isPaypalOffline() {
      if(this.isPaypalOffline) {
        this.$nextTick(function() {
          this.scrollToTopMixin("#paypalOfflineErrorMessage");
        });
      }
    }
  }  
};