export var Helpers = {
  getSupporterPortalErrorMessage() {
    return "Oops, something went wrong. Please <a href='/about-us/contact' class='tsf-link'>contact us</a> if you require assistance.";
  },

  getErrorResponseMessage () {
    return "Sorry, My Smith Family is currently under maintenance and is temporarily unavailable. Please check back soon or <a href='/about-us/contact' class='tsf-link'>contact us</a> for assistance.";
  },
  getFullErrorResponse(err) {
    if(err != null && err.response != null && err.response.data != null){
      return err.response.data;
    }

    return this.getErrorResponseMessage(err);
  },

  getDonationServerUnavailableMessage () {
    return "Sorry, our donation form is currently under maintenance and is temporarily unavailable. Please check back soon or for assistance give us a call: <a href=\"tel:1800 024 069\">1800 024 069</a>.";
  },
  
  // constructing data for donation create transaction
  constructDataForCreateTransaction(state, isCreditCardTransaction, extraData) {
    let data;
    if(isCreditCardTransaction) {
      data = {
        ...state.donationAmountFormValues,
        PaymentType: "creditCard",
        RecaptchaToken:extraData.recaptchaToken,
        DonorDetails: {
          SupporterId: state.donationPersonalDetailsFormValues.SupporterId,
          FirstName: state.donationPersonalDetailsFormValues.FirstName,
          LastName: state.donationPersonalDetailsFormValues.LastName,
          Email: state.donationPersonalDetailsFormValues.Email,
          DonorType: state.donationPersonalDetailsFormValues.DonorType.toLowerCase(),
          ContactNumber: state.donationPersonalDetailsFormValues.ContactNumber,
          ContactNumberType:
            state.donationPersonalDetailsFormValues.ContactNumberType,
          CompanyName: state.donationPersonalDetailsFormValues.CompanyName,
          Address1: state.multiAddressValues.Address1,
          Address2: state.multiAddressValues.Address2,
          Address3: state.multiAddressValues.Address3,
          Suburb: state.multiAddressValues.Suburb,
          State: state.multiAddressValues.State,
          Postcode: state.multiAddressValues.Postcode,
          Country: state.multiAddressValues.Country
        },
        CardDetails:{
          PayframeToken: extraData.payframeToken,
          PayframeKey: extraData.payframeKey,
          CardName: extraData.cardName
        }
      };
    } else {
      data = {
        ...state.donationAmountFormValues
      }
    }
    if(data.FormType === 'ChildSponsorship' || data.FormType == 'Renewal') {
      // init empty objects
      var sponsorshipModel = {
        SchoolStudents: 0,
        SchoolAmount: 0,
        SeniorSchoolStudents: 0,
        SeniorSchoolAmount: 0,
        TertiaryFullStudents: 0,
        TertiaryFullAmount: 0
      };
      var sponsorshipDonationDetailModels = [];
  
      if(data.SponsorChildrenPreferences) {
        //new sponsorship
        sponsorshipModel.SchoolStudents = data.SponsorChildrenPreferences.length;
        sponsorshipModel.SchoolAmount = data.DonationAmount;
  
        data.SponsorPreferences = '';
        for(let i = 0; i < data.SponsorChildrenPreferences.length; i++) {
          let sponsorChildPref = data.SponsorChildrenPreferences[i];
          if(sponsorChildPref.anyPref === data.defaultSponsorOptions.anyPref) {
            //no preference
            data.SponsorPreferences += `${sponsorChildPref.anyPref}`;
          } else {
            data.SponsorPreferences += `${sponsorChildPref.genderPref},${sponsorChildPref.agePref},${sponsorChildPref.statePref}`;
          }
          if(i < data.SponsorChildrenPreferences.length - 1) {
            //this is not the last one
            data.SponsorPreferences += `|`;
          }
        }
      } else if(data.SponsoredChildForRenewals) {
        //renewal of sponsorship

        for(let i = 0; i < data.SponsoredChildForRenewals.length; i++) {
          let renewalChild = data.SponsoredChildForRenewals[i];
          if(renewalChild.Selected) {
             // build student details
             var rowModel = {
              StudentId: renewalChild.ParticipantId,
              StudentFullName: renewalChild.FirstName,
              AllocationId: renewalChild.AllocationId,
              EducationLevel: renewalChild.EducationLvl || "",
              DonationPledgeId: renewalChild.DonationPledgeId,
              ParticipantSponsorshipBandId: renewalChild.ParticipantSponsorshipBandId
            };
  
            // get amount from frequency (as number for now)
            var amount = data.PaymentFrequency === "Monthly" ? renewalChild.MonthlyAmount : renewalChild.AnnualAmount;
            rowModel.Amount = parseFloat(amount).toFixed(2);
  
            // push row to collection
            sponsorshipDonationDetailModels.push(rowModel);
  
            // assign the temp vars to specific objects depending on type
            switch (rowModel.EducationLevel.toLowerCase()) {
              case 'senior school':
                sponsorshipModel.SeniorSchoolStudents++;
                sponsorshipModel.SeniorSchoolAmount += parseInt(rowModel.Amount);
                break;
              case 'tertiary university':
              case 'tertiary tafe':
                sponsorshipModel.TertiaryFullStudents++;
                sponsorshipModel.TertiaryFullAmount += parseInt(rowModel.Amount);
                break;
              case 'school':
                sponsorshipModel.SchoolStudents++;
                sponsorshipModel.SchoolAmount += parseInt(rowModel.Amount);
                break
            }
          }
        }
  
        // fix decimals, may not be necessary
        sponsorshipModel.SchoolAmount = parseFloat(sponsorshipModel.SchoolAmount).toFixed(2);
        sponsorshipModel.SeniorSchoolAmount = parseFloat(sponsorshipModel.SeniorSchoolAmount).toFixed(2);
        sponsorshipModel.TertiaryFullAmount = parseFloat(sponsorshipModel.TertiaryFullAmount).toFixed(2);
        sponsorshipModel.SchoolStudents = sponsorshipModel.SchoolStudents.toString();
        sponsorshipModel.SeniorSchoolStudents = sponsorshipModel.SeniorSchoolStudents.toString();
        sponsorshipModel.TertiaryFullStudents = sponsorshipModel.TertiaryFullStudents.toString();
      }
  
      data.ChildSponsorshipDonation = sponsorshipModel;
      data.ChildSponsorshipDonationDetails = sponsorshipDonationDetailModels;
    }
    return data;
  }  
}