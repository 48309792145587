import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/forgotapi`;
const axioService = new AxiosService(API_URL);

export var ResetPasswordApi = {
  GetResetPasswordContent() {
    const url = `/resetpassword`;
    return axioService.get(url);
  },
  SumbmitResetPassword(data) {
    const url = `/resetpassword`;
    return axioService.post(url, data);
  }
};
