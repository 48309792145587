import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/ProfileMvcAPI`;
const axioService = new AxiosService(API_URL);
const passToken = true;

export var ProfileService = {
  getChangePasswordData() {
    const url = `/ChangePassword`;
    return axioService.getPromise(url, passToken);
  },

  changePasswordSubmit(data) {
    const url = `/ChangePassword`;
    return axioService.postPromise(url, data, passToken);
  },

  getEditProfileData(datasource) {
    const url = `/supporterprofile?datasource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  updateSupporterProfile(data) {
    const url = `/updateprofile`;
    return axioService.postPromise(url, data, passToken);
  },

  getCommsPrefData(datasource) {
    const url = `/CommunicationPreferences?datasource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  resetCommBreak(data) {
    const url = `/UpdateCommsPreferencesBreak`;
    return axioService.postPromise(url, data, passToken);
  },

  updateCommsPref(data) {
    const url = `/UpdateCommsPreferences`;
    return axioService.postPromise(url, data, passToken);  
  },   
};