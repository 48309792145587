import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/SponsoredStudentAPI`;
const axioService = new AxiosService(API_URL);
const passToken = true;

let queryString = window.location.search.replace('?', '&'); //replace ? with & and add querystring as last parameter

export var FileGalleryService = {
  getFileGalleryData(datasource) {
    const url = `/GetStudentFileGallery?dataSource=${datasource}` + queryString;
    return axioService.getPromise(url, passToken);
  }
};