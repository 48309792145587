import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/SponsoredStudentAPI`;
const axioService = new AxiosService(API_URL);
const passToken = true;

export var CorrespondenceHistoryService = {
  getCorrespondenceHistory(datasource) {
    const url = `/CorrespondenceHistory?dataSource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },
};
