import { ProfileService } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers';
export const state = {
  changePasswordData: {},
  changePasswordResponse: {},
  editProfileData: {},
  editProfileResponse: {},
  commsPrefData: {},
  updateCommsPref: {},
  resetCommBreak: {},
};

export const mutations = {
  Set_ChangePassword_Content(state, dt) {
    state.changePasswordData = dt;
  },
  Set_ChangePassword_Response(state, dt) {
    state.changePasswordResponse = dt;
  },
  Set_EditProfile_Content(state, dt) {
    state.editProfileData = dt;
  },
  Set_UpdateSupporterProfile_Response(state, dt) {
    state.editProfileResponse = dt;
  },
  Set_CommsPref_Content(state, dt) {
    state.commsPrefData = dt;
  },
  Set_CommsPref_Response(state, dt) {
    state.updateCommsPref = dt;
  },
  Set_ResetCommBreak_Response(state, dt) {
    state.resetCommBreak = dt;
  },
};

export const actions = {
  getChangePasswordData({ commit }) {
    return ProfileService.getChangePasswordData()
      .then(res => {
        res.ApiHasError = false;
        commit("Set_ChangePassword_Content", res);
      })
      .catch(err => {
        commit("Set_ChangePassword_Content", err);
      });
  },

  changePasswordSubmit({ commit, dispatch, state }, data) {
    dispatch("setLoadingHeader", state.changePasswordData.ResultValue.ProcessingOverlay.ProcessingHeading);
    dispatch("setLoadingMessage", state.changePasswordData.ResultValue.ProcessingOverlay.ProcessingText);
    dispatch("setLoadingImage", state.changePasswordData.ResultValue.ProcessingOverlay.ProcessingLogoImageSrc);
    dispatch("setLoading", true);
    return ProfileService.changePasswordSubmit(data)
      .then(res => {
        let data = res.data;
        dispatch("setLoading", false);
        data.ApiHasError = false;
        commit("Set_ChangePassword_Response", data);
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] }
        };
        commit("Set_ChangePassword_Response", res);
        dispatch("setLoading", false);
      });
  },

  getEditProfileData({ commit }, data) {
    return ProfileService.getEditProfileData(data)
      .then(res => {
        res.ApiHasError = false;
        commit("Set_EditProfile_Content", res);
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] },
          PageContent: {},
          FormFields: {}
        };
        commit("Set_EditProfile_Content", res);
      });
  },

  updateSupporterProfile({ commit, dispatch, state }, data) {
    dispatch("setLoadingHeader", state.editProfileData.ResultValue.ProcessingOverlay.ProcessingHeading);
    dispatch("setLoadingMessage", state.editProfileData.ResultValue.ProcessingOverlay.ProcessingText);
    dispatch("setLoadingImage", state.editProfileData.ResultValue.ProcessingOverlay.ProcessingLogoImageSrc);
    dispatch("setLoading", true);

    return ProfileService.updateSupporterProfile(data)
      .then(res => {
        let data = res.data;
        dispatch("setLoading", false);
        data.ApiHasError = false;
        commit("Set_UpdateSupporterProfile_Response", data);
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] }
        };
        commit("Set_UpdateSupporterProfile_Response", res);
        dispatch("setLoading", false);
      });
  },

  getCommsPrefData({ commit }, data) {
    return ProfileService.getCommsPrefData(data)
      .then(res => {
        res.ApiHasError = false;
        commit("Set_CommsPref_Content", res);
      })
      .catch(err => {
        commit("Set_CommsPref_Content", err);
      });
  },

  updateCommsPref({ commit, dispatch, state }, data)  {
    dispatch("setLoadingHeader", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingHeading);
    dispatch("setLoadingMessage", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingText);
    dispatch("setLoadingImage", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingLogoImageSrc);
    dispatch("setLoading", true);
        return ProfileService.updateCommsPref(data)
      .then(res => {
        let data = res.data;
        if (data.IsSuccess) {
          dispatch("setLoading", false);
          data.ApiHasError = false;
          commit("Set_CommsPref_Response", data);          
        } else {
          dispatch("setLoading", false);
          data.ApiHasError = true;
          data.Message = Helpers.getSupporterPortalErrorMessage()
          commit("Set_CommsPref_Response", data);
        }
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] }
        };
        commit("Set_CommsPref_Response", res);
        dispatch("setLoading", false);
      });
  },  
  resetCommBreak({ commit, dispatch, state }, data) {
    dispatch("setLoadingHeader", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingHeading);
    dispatch("setLoadingMessage", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingText);
    dispatch("setLoadingImage", state.commsPrefData.ResultValue.ProcessingOverlay.ProcessingLogoImageSrc);
    dispatch("setLoading", true);
    return ProfileService.resetCommBreak(data)
      .then(res => {
        let data = res.data;
        dispatch("setLoading", false);
        data.ApiHasError = false;
        commit("Set_ResetCommBreak_Response", data);
      })
      .catch(err => {
        let res = {
          ApiHasError: true,
          ResultValue: { ValidationMessages: [Helpers.getErrorResponseMessage(err)] }
        };
        commit("Set_ResetCommBreak_Response", res);
        dispatch("setLoading", false);
      });
  },  
};
