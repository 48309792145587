import { ResetPasswordApi } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers'; 

export const state = {
  resetData: undefined,
  resetPasswordServerResponse: {}
};
export const mutations = {
  SET_RESET_PASSWORD_CONTENTS(state, dt) {
    state.resetData = dt;
  },
  SET_RESET_PASSWORD_SERVER_RESPONSE(state, dt) {
    state.resetPasswordServerResponse = dt;
  }
};

export const actions = {
  getResetPasswordContents({ commit, dispatch }) {
    dispatch("setLoading", true);
    ResetPasswordApi.GetResetPasswordContent()
      .then(res => {
        setTimeout(function () {
          dispatch("setLoading", false);
        }, 500);
        res.ApiHasError = false;
        commit("SET_RESET_PASSWORD_CONTENTS", res);
      })
      .catch(err => {        
        let res = {
          ApiHasError: true,
          AttemptSuceeded: false,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        };
        dispatch("setLoading", false);       
        commit("SET_RESET_PASSWORD_CONTENTS", res);
      });
  },
  sumbmitResetPassword({ state, commit, dispatch }, info) {
    dispatch("setLoadingHeader", state.resetData.ProcessingOverlay.ProcessingHeading);
    dispatch("setLoadingMessage", state.resetData.ProcessingOverlay.ProcessingText);
    dispatch("setLoadingImage", state.resetData.ProcessingOverlay.ProcessingLogoImageSrc);
    dispatch("setLoading", true);

    ResetPasswordApi.SumbmitResetPassword(info)
      .then(res => {
        commit("SET_RESET_PASSWORD_SERVER_RESPONSE", res);
      })
      .catch(err => {
        let res = {
          ServerError: true,
          AttemptSuceeded: false,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        };       
        commit("SET_RESET_PASSWORD_SERVER_RESPONSE", res);
      });
  }
};

export const getters = {};
