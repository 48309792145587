import { RegistrationApi } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers';  

export const state = {
  AccountId: "",
  Eligibility: "",
  step0Data: undefined,
  step0HalfData: {},
  step0ServerResponse: {},
  step1Data: {},
  step1ServerResponse: {},
  step2Data: {},
  step2ServerResponse: {},
  displayStep0Selection: false,
  displayStep1: false,
  displayStep2: false,
  currentStep: 0
};

export const mutations = {
  SET_REGO_STEP0_CONTENTS(state, dt) {
    state.step0Data = dt;
  },
  SET_REGO_STEP0_SERVER_RESPONSE(state, dt) {
    state.step0ServerResponse = dt;
  },
  SET_REGO_STEP0_HALF_CONTENTS(state, dt) {
    state.step0HalfData = dt;
  },
  SET_REGO_STEP1_CONTENTS(state, dt) {
    state.step1Data = dt;
  },
  SET_REGO_STEP1_SERVER_RESPONSE(state, dt) {
    state.step1ServerResponse = dt;
  },
  SET_REGO_STEP2_CONTENTS(state, dt) {
    state.step2Data = dt;
  },
  SET_REGO_STEP2_SERVER_RESPONSE(state, dt) {
    state.step2ServerResponse = dt;
  },
  SET_ACCOUNT_ID(state, dt) {
    state.AccountId = dt;
  },
  SET_ELIGIBILITY(state, dt) {
    state.Eligibility = dt;
  },
  SET_DISPLAY_STEP0_SELECTION(state, dt) {
    state.displayStep0Selection = dt;
  },
  SET_DISPLAY_STEP1(state, dt) {
    state.displayStep1 = dt;
  },
  SET_DISPLAY_STEP2(state, dt) {
    state.displayStep2 = dt;
  },
  SET_CURRENT_STEP(state, dt) {
    state.currentStep = dt;
  }
};

export const actions = {
  getRegoStep0Contents({ commit, dispatch }) {
    dispatch("setLoading", true);

    RegistrationApi.GetContentStepZero()
      .then(res => {
        setTimeout(function() {
          dispatch("setLoading", false);
        }, 500);
        res.ApiHasError = false;
        commit("SET_REGO_STEP0_CONTENTS", res);
        commit("SET_CURRENT_STEP", 0);
      })
      .catch(err => {
        dispatch("setLoading", false);
        let res = {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err),
          PageContent:{},
          FormFields:{}
        };
        commit("SET_REGO_STEP0_CONTENTS", res);
        console.log(err);
      });
  },
  getPortalEligability({ commit, dispatch }, Id) {
    RegistrationApi.GetPortalEligability(Id)
      .then(res => {
        commit("SET_REGO_STEP0_SERVER_RESPONSE", res);
        commit("SET_ACCOUNT_ID", Id);
        if (
          state.step0ServerResponse.PortalEligibility === "Both" ||
          state.step0ServerResponse.PortalEligibility ===
            "SupporterRegisteredFamilyEligible" ||
          state.step0ServerResponse.PortalEligibility ===
            "FamilyRegisteredSupporterEligible"
        ) {
          commit("SET_DISPLAY_STEP0_SELECTION", true);
          commit("SET_REGO_STEP0_HALF_CONTENTS", state.step0ServerResponse);
        } else {
          if (!state.step0ServerResponse.ValidationMessage) {
            var eligibility = state.step0ServerResponse.PortalEligibility;

            commit("SET_ELIGIBILITY", eligibility);
            // Go to Step 1;
            dispatch("getRegoStep1Contents", state.Eligibility);
          }
        }
      })
      .catch(err => {        
        var message = {};
        message.ValidationMessage = Helpers.getErrorResponseMessage(err);
          commit("SET_REGO_STEP0_SERVER_RESPONSE", message);        
      });
  },
  getRegoStep1Contents({ commit, dispatch }, eligibility) {
    dispatch("setLoading", true);
    commit("SET_ELIGIBILITY", eligibility);
    RegistrationApi.GetContentStepOne(eligibility)
      .then(res => {
        commit("SET_DISPLAY_STEP0_SELECTION", false);
        commit("SET_DISPLAY_STEP1", true);
        commit("SET_CURRENT_STEP", 1);
        commit("SET_REGO_STEP1_CONTENTS", res);
        dispatch("setLoading", false);
        console.log("resposne", res);
      })
      .catch(err => {
        dispatch("setLoading", false);
        console.log(err);
      });
  },
  submitStep01Form({ commit, dispatch }, data) {
    dispatch("setLoading", true);
    RegistrationApi.ValidateStepOne(data)
      .then(res => {
        commit("SET_REGO_STEP1_SERVER_RESPONSE", res);
        if (state.step1ServerResponse.AttemptSuceeded === true) {
          RegistrationApi.GetContentStepTwo()
            .then(res => {
              commit("SET_DISPLAY_STEP2", true);
              commit("SET_CURRENT_STEP", 2);
              commit("SET_REGO_STEP2_CONTENTS", res);
            })
            .catch(err => {
              console.log(err);
            });
        }
        dispatch("setLoading", false);
      })
      .catch(err => {
        let res = {
          AttemptSuceeded: false,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        };
        commit("SET_REGO_STEP1_SERVER_RESPONSE", res);
        dispatch("setLoading", false);
        console.log(err);
      });
  },
  submitStep02Form({ commit, dispatch }, data) {
    var formData = data.formData;
    var modalInfo = data.modalInfo;
    dispatch("setLoadingHeader", modalInfo.Header);
    dispatch("setLoadingMessage", modalInfo.Message);
    dispatch("setLoadingImage", modalInfo.Image);
    dispatch("setLoading", true);

    RegistrationApi.SubmitRegistration(formData)
      .then(res => {        
        if(res.LoginSuccess === false)
        {
        dispatch("setLoading", false);     
        }
        commit("SET_REGO_STEP2_SERVER_RESPONSE", res);
      })
      .catch(err => {
        dispatch("setLoading", false);
        let res = {
          LoginSuccess: false,
          RedirectUrl: "",
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        };
        commit("SET_REGO_STEP2_SERVER_RESPONSE", res);
        console.log(err);
      });
  }
};
