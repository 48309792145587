import { SupporterService } from "../../services/ServiceWrapper";
import { Helpers } from "../helpers";
export const state = {
  notificationsData: {},
  yourGivingChartData: {},
  yourGivingSummaryData: {},
  yourStudentsSummaryData: {},
  studentsPageData: {},
  donationsHistoryData: {},
  sendReceiptStatuses: {},
  sponsoredStudentMessageGetResponse: {},
  sponsoredStudentMessagePostResponse: {},
};

export const mutations = {
  Set_Notifications_Content(state, dt) {
    state.notificationsData = dt;
  },
  Set_YourGivingChart_Content(state, dt) {
    state.yourGivingChartData = dt;
  },
  Set_YourGivingSummary_Content(state, dt) {
    state.yourGivingSummaryData = dt;
  },
  Set_YourStudentsSummary_Content(state, dt) {
    state.yourStudentsSummaryData = dt;
  },
  Remove_Notification(state, notification) {
    let index = state.notificationsData.ResultValue.Items.indexOf(notification);
    if (index > -1) {
      state.notificationsData.ResultValue.Items.splice(index, 1);
    }
  },
  Set_StudentsPage_Content(state, dt) {
    state.studentsPageData = dt;
  },
  Replace_Notification(state, dt) {
    let index = state.notificationsData.ResultValue.Items.indexOf(dt.oldNotification);
    if (index > -1) {
      state.notificationsData.ResultValue.Items.splice(index, 1, dt.newNotification);
    }
  },
  Set_DonationsHistory_Response(state, dt) {
    state.donationsHistoryData = dt;
  },
  Set_SendReceiptEmailStatus(state, dt) {
    state.sendReceiptStatuses[dt.id] = dt
  },
  Set_SponsoredStudentMessage_GetResponse(state, dt) {
    state.sponsoredStudentMessageGetResponse = dt;
  },
  Set_SponsoredStudentMessage_PostResponse(state, dt) {
    state.sponsoredStudentMessagePostResponse = dt;
  }
};

export const actions = {
  getNotifications({ commit }, datasource) {
    return SupporterService.getNotifications(datasource)
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_Notifications_Content", res);
        } else {
          commit("Set_Notifications_Content", {
            ApiHasError: true,
            ValidationMessage:
              res.Message || Helpers.getSupporterPortalErrorMessage(),
          });
        }
      })
      .catch((err) => {
        commit("Set_Notifications_Content", {
          ApiHasError: true,
          ValidationMessage: Helpers.getSupporterPortalErrorMessage(err),
        });
      });
  },

  getStudents({ commit }, params) {
    return SupporterService.getStudents(params)
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_YourStudentsSummary_Content", res);
        } else {
          commit("Set_YourStudentsSummary_Content", {
            ApiHasError: true,
            ValidationMessage: res.Message || Helpers.getErrorResponseMessage(),
          });
        }
      })
      .catch((err) => {
        commit("Set_YourStudentsSummary_Content", {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err),
        });
      });
  },

  getStudentsPage({ commit, dispatch, state }, params) {
    dispatch(
      "setLoadingHeader",
      state.yourStudentsSummaryData.ResultValue.PageContent.ProcessingOverlay
        .ProcessingHeading
    );
    dispatch(
      "setLoadingMessage",
      state.yourStudentsSummaryData.ResultValue.PageContent.ProcessingOverlay
        .ProcessingText
    );
    dispatch(
      "setLoadingImage",
      state.yourStudentsSummaryData.ResultValue.PageContent.ProcessingOverlay
        .ProcessingLogoImageSrc
    );
    dispatch("setLoading", true);

    return SupporterService.getStudentsPage(
      params.pageNumber,
      params.pageSize,
      params.sortOrder,
      params.datasource
    )
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_StudentsPage_Content", res);
        } else {
          commit("Set_StudentsPage_Content", {
            ApiHasError: true,
            ValidationMessage: res.Message || Helpers.getErrorResponseMessage(),
          });
        }
        dispatch("setLoading", false);
      })
      .catch((err) => {
        commit("Set_StudentsPage_Content", {
          ApiHasError: true,
          ValidationMessage: Helpers.getSupporterPortalErrorMessage(err),
          PageContent: {},
          FormFields: {},
        });
        dispatch("setLoading", false);
      });
  },

  getYourGivingSummary({ commit }, datasource) {
    return SupporterService.getYourGivingSummary(datasource)
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_YourGivingSummary_Content", res);
        } else {
          commit("Set_YourGivingSummary_Content", {
            ApiHasError: true,
            ValidationMessage:
              res.Message || Helpers.getSupporterPortalErrorMessage(),
          });
        }
      })
      .catch((err) => {
        commit("Set_YourGivingSummary_Content", {
          ApiHasError: true,
          ValidationMessage: Helpers.getSupporterPortalErrorMessage(err),
        });
      });
  },

  getYourGivingChart({ commit }, datasource) {
    return SupporterService.getYourGivingChart(datasource)
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_YourGivingChart_Content", res);
        } else {
          commit("Set_YourGivingChart_Content", {
            ApiHasError: true,
            ValidationMessage:
              res.Message || Helpers.getSupporterPortalErrorMessage(),
          });
        }
      })
      .catch((err) => {
        commit("Set_YourGivingChart_Content", {
          ApiHasError: true,
          ValidationMessage: Helpers.getSupporterPortalErrorMessage(err),
        });
      });
  },

  notificationActioned({ commit }, notification) {
    //remove from our list on the screen
    commit("Remove_Notification", notification);

    return SupporterService.notificationActioned(notification)
      .then(() => {
        //do nothing this is a background process
      })
      .catch
      //do nothing this is a background process
      ();
  },

  notificationApiTrigger({ commit }, data) {
    let notification = data.notification;
    return SupporterService.notificationApiTrigger(
      data.notificationAction
    )
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          commit("Replace_Notification", {
            oldNotification: notification,
            newNotification: data.ResultValue,
          });
        } else {
          notification["ErrorMessage"] = data.Message || Helpers.getSupporterPortalErrorMessage();
        }
      })
      .catch((err) => {
        notification["ErrorMessage"] = Helpers.getSupporterPortalErrorMessage(err);
      });
  },

  getSupporterDonationsHistory({ commit }, datasource) {
    return SupporterService.getSupporterDonationsHistory(datasource)
      .then((res) => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_DonationsHistory_Response", res);
        } else {
          commit("Set_DonationsHistory_Response", {
            ApiHasError: true,
            ValidationMessage:
              res.Message || Helpers.getSupporterPortalErrorMessage(),
          });
        }
      })
      .catch((err) => {
        commit("Set_DonationsHistory_Response", {
          ApiHasError: true,
          ValidationMessage: Helpers.getSupporterPortalErrorMessage(err),
        });
      });
  },

  sendReceiptEmail({ commit }, payload) {
    commit("Set_SendReceiptEmailStatus", {
      id: payload.receiptNumber,
      statusMessage: "Sending",
      inProgress: true,
    });

    return SupporterService.sendReceiptEmail({ receiptNumber: payload.receiptNumber, datasource: payload.datasource })
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          commit("Set_SendReceiptEmailStatus", {
            id: payload.receiptNumber,
            statusMessage: data.Message || "Sent",
            inProgress: false,
            isSuccess: true,
          });
        } else {
          commit("Set_SendReceiptEmailStatus", {
            id: payload.receiptNumber,
            statusMessage: data.Message || "Try again",
            inProgress: false,
            isSuccess: false,
          });
        }
      })
      .catch(() => {
        commit("Set_SendReceiptEmailStatus", {
          id: payload.receiptNumber,
          statusMessage: "Try again",
          inProgress: false,
          isSuccess: false,
        });
      });
  },

  getSupporterSponsoredStudentMessage({ commit }, params) {
    return SupporterService.getSupporterSponsoredStudentMessage(params)
      .then(res => {
        if (res.IsSuccess) {
          res.ApiHasError = false;
          commit("Set_SponsoredStudentMessage_GetResponse", res);
        } else {
          commit("Set_SponsoredStudentMessage_GetResponse", {
            ApiHasError: true,
            ValidationMessage: res.Message || Helpers.getErrorResponseMessage()
          });
        }
      })
      .catch(err => {
        commit("Set_SponsoredStudentMessage_GetResponse", {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        });
      });
  },
  postSupporterSponsoredStudentMessage({ commit, dispatch }, params) {

    dispatch("setLoadingHeader", params.FormFields.ProcessingHeading);
    dispatch("setLoadingMessage", params.FormFields.ProcessingText);
    dispatch("setLoadingImage", params.FormFields.ProcessingLogoImage);
    dispatch("setLoading", true);

    return SupporterService.postSupporterSponsoredStudentMessage(params)
      .then(res => {
        dispatch("setLoading", false);
        let data = res.data;
        if (data.IsSuccess) {
          data.ApiHasError = false;
          commit("Set_SponsoredStudentMessage_PostResponse", data);
        } else {
          commit("Set_SponsoredStudentMessage_PostResponse", {
            ApiHasError: true,
            ValidationMessage: data.Message || Helpers.getErrorResponseMessage()
          });
        }
      })
      .catch(err => {
        dispatch("setLoading", false);
        commit("Set_SponsoredStudentMessage_PostResponse", {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        });
      });
  },
  pledgeIncreaseAmountSubmit({ dispatch, commit }, postData) {

    dispatch("setLoadingHeader", postData.header);
    dispatch("setLoadingMessage", postData.message);
    dispatch("setLoadingImage", postData.image);
    dispatch("setLoading", true);

    return SupporterService.pledgeIncreaseAmountSubmit(postData)
      .then(res => {
        commit("SET_PLEDGE_INCREASE_AMOUNT_RESPONSE", res.data);
        if (res.data.IsSuccess) {
          window.location.href = res.data.ResultValue.RedirectUrl;
        } else {
          dispatch("setLoading", false);
        }
      })
      .catch(error => {
        commit("SET_DONATION_SERVER_GENERAL_ERROR");
        dispatch("setLoading", false);
      });
  }
};