import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/SupporterPortalPaymentsAPI`;
const axioService = new AxiosService(API_URL);
const passToken = true;

export var RegularPaymentService = {
  getPaymentDetails(datasource) {
    const url = `/GetPaymentDetails?dataSource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  addCardSubmit(data) {
    const url = `/SubmitCardDetails`;
    return axioService.postPromise(url, data, passToken);
  },
};
