import AxiosService from "./AxiosService";
import { QueryStringService } from "./QueryStringService";

const API_URL = `${process.env.VUE_APP_URL}api/SupporterPortalMvcAPI`;
const axioService = new AxiosService(API_URL);

const STUDENT_API_URL = `${process.env.VUE_APP_URL}api/SponsoredStudentAPI`;
const studentAxioService = new AxiosService(STUDENT_API_URL);

const passToken = true;

export var SupporterService = {
  getNotifications(params) {
    const url = `/getnotifications?dataSource=${params.datasource}&participantId=${params.participantId}`;
    return axioService.getPromise(url, passToken);
  },

  notificationActioned(notification) {
    const url = `/dismissnotification`;
    return axioService.postPromise(url, {
      notificationId: notification.Id,
      participantId: notification.ParticipantId,
      notificationTypeId: notification.NotificationTypeId,
    },
    passToken);
  },

  notificationApiTrigger(notificationAction) {
    return axioService.postPromise(notificationAction.Url, null, passToken);
  },

  getYourGivingSummary(datasource) {
    const url = `/latestDonationPledgeActivity?dataSource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  getYourGivingChart(datasource) {
    const url = `/donationsByFYGraph?dataSource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  getStudents(params) {
    var extraParams = "";
    let participantId = QueryStringService.getUrlParameter("participantId");  
    if (participantId){
      extraParams = extraParams + "&participantId=" +  participantId;
    }
    let messageType = QueryStringService.getUrlParameter("MessageType");  
    if (messageType){
      extraParams = extraParams + "&MessageType=" +  messageType;
    }
    const url = `/SponsoredStudents?dataSource=${params.datasource}${extraParams}`;
    return studentAxioService.getPromise(url, passToken);
  },

  getStudentsPage(pageNumber, pageSize, sortOrder, datasource) {
    const url = `/SponsoredStudents?pageNumber=${pageNumber}&pageSize=${pageSize}&sortOrder=${sortOrder}&dataSource=${datasource}`;
    return studentAxioService.getPromise(url, passToken);
  },

  getSupporterDonationsHistory(datasource) {
    const url = `/GetTaxReceipts?dataSource=${datasource}`;
    return axioService.getPromise(url, passToken);
  },

  sendReceiptEmail(data) {
    const url = `/TaxReceiptEmail`;
    return axioService.postPromise(url, data, passToken);
  },
  getSupporterSponsoredStudentMessage(datasource) {
    const url = `/SponsoredStudentMessage?dataSource=${datasource}`;
    return studentAxioService.getPromise(url, passToken);
  },
  postSupporterSponsoredStudentMessage(data) {
    const url = `/SponsoredStudentMessage`;
    return studentAxioService.postPromise(url, data, passToken);
  },
  pledgeIncreaseAmountSubmit(data) {
    const url = `/PostIncreaseRegularPayment`;
    return axioService.postPromise(url, data, passToken);
  }
};
