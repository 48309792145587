import { RegularPaymentService } from "../../services/ServiceWrapper";
import { Helpers } from "../helpers";

const getDefaultState = () => {
  return {};
};

export const state = {
  addCardDetailsResponse: getDefaultState,
  addCardErrorMessage: "",
  isAddCardError: false,
  paymentDetailsResponse: {},
  showPayframe: false,
};

export const mutations = {
  Set_PaymentDetails_Response(state, dt) {
    state.paymentDetailsResponse = dt;
  },
  Set_AddCardDetails_Response(state, dt) {
    state.addCardDetailsResponse = dt;
  },
  Reset_CardSubmit_Response(state) {
    state.addCardDetailsResponse = getDefaultState;
  },
  setPayframeStatus(state, dt) {
    state.showPayframe = dt;
  },
};

export const actions = {
  getPaymentDetails({ commit, dispatch }, datasource) {
    return RegularPaymentService.getPaymentDetails(datasource)
      .then((res) => {
        res.ApiHasError = false;
        commit("Set_PaymentDetails_Response", res);
        dispatch("setLoading", false);
      })
      .catch((err) => {
        let res = {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err),
          PageContent: {},
          FormFields: {},
        };
        commit("Set_PaymentDetails_Response", res);
        dispatch("setLoading", false);
      });
  },

  addCardSubmit({ commit, dispatch }, data) {
    dispatch("setLoading", true);
    dispatch("setAddCardModalInfo");
    dispatch("setIsAddCardError", false);
    commit("setPayframeStatus", false);

    return RegularPaymentService.addCardSubmit(data)
      .then((res) => {
        let data = res.data;
        if (data.IsSuccess) {
          data.ApiHasError = false;
          commit("Set_AddCardDetails_Response", data);
        } else {
          data.ApiHasError = false; // Backend Failed 
          commit("Set_AddCardDetails_Response", data);
          dispatch("setIsAddCardError", true);
          dispatch(
            "setAddCardErrorMessage",
            data.Message
          );
          dispatch("setLoading", false);
        }
      })
      .catch((err) => {
        let res = {
          ApiHasError: true, // MW Failed!
          ValidationMessage: Helpers.getErrorResponseMessage(err),
        };
        commit("Set_AddCardDetails_Response", res);
        dispatch("setIsAddCardError", true);
        dispatch(
          "setAddCardErrorMessage",
          Helpers.getErrorResponseMessage(err)
        );
        dispatch("setLoading", false);
      });
  },

  setIsAddCardError({ state }, isAddCardError) {
    state.isAddCardError = isAddCardError;
  },
  setPayframeUnavailable({ state, commit, dispatch }, isPayframeError) {
    let res = {
      ApiHasError: true,
      IsSuccess: false,
      ValidationMessage: isPayframeError,
    };
    commit("Set_AddCardDetails_Response", res);
    state.isAddCardError = true
    dispatch(
      "setAddCardErrorMessage",
      isPayframeError
    );
    dispatch("setLoading", false);
  },
  setAddCardErrorMessage({ state }, message) {
    state.addCardErrorMessage = message;
  },
  setAddCardModalInfo({ state, dispatch }) {
    let data = state.paymentDetailsResponse.ResultValue.ProcessingOverlay;
    if (data) {
      dispatch("setLoadingHeader", data.ProcessingHeading);
      dispatch("setLoadingMessage", data.ProcessingText);
      dispatch("setLoadingImage", data.ProcessingLogoImageSrc);
    }
  },
};

export const getters = {
  isAddCardError: (state) => {
    return state.isAddCardError;
  },
  // getAddCardErrorMessage: (state) => {
  //   return state.addCardErrorMessage;
  // },
};
