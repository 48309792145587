import { createStore } from 'vuex'
import * as donation from "./modules/donationStore.js";
import * as registration from "./modules/registrationStore.js";
import * as login from "./modules/loginStore.js";
import * as loading from "./modules/loadingStore.js";
import * as forgot from "./modules/forgotStore.js";
import * as resetPassword from "./modules/resetPasswordStore.js";
import * as profile from "./modules/profileStore.js";
import * as supporter from "./modules/supporterStore.js";
import * as regularPayment from "./modules/regularPaymentStore.js";
import * as studentFileGallery from "./modules/studentFileGalleryStore.js";
import * as correspondenceHistory from "./modules/correspondenceHistoryStore.js";

export default createStore({
  state: {},
  mutation: {},
  action: {},
  modules: {
    donation,
    registration,
    login,
    loading,
    forgot,
    resetPassword,
    profile,
    supporter,
    regularPayment,
    studentFileGallery,
    correspondenceHistory
  }
});
