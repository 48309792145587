import { CorrespondenceHistoryService } from "../../services/ServiceWrapper";
import { Helpers } from "../helpers";
export const state = {
  studentCorrespondenceHistoryData: {},
};

export const mutations = {
  Set_CorrespondenceHistory_Response(state, dt) {
    state.studentCorrespondenceHistoryData = dt;
  },
};

export const actions = {
    getCorrespondenceHistory({ dispatch, commit }, datasource) {
        return CorrespondenceHistoryService.getCorrespondenceHistory(datasource)
            .then(res => {
                if (res.IsSuccess) {
                    res.ApiHasError = false;
                    commit("Set_CorrespondenceHistory_Response", res);
                    dispatch("setLoading", false);
                } else {
                    res.ApiHasError = true;
                    commit("Set_CorrespondenceHistory_Response", res);
                    dispatch("setLoading", false);
                }
            })
            .catch(err => {
                let res = {
                    ApiHasError: true,
                    ValidationMessage: Helpers.getErrorResponseMessage(err),
                    err: err,
                };
                commit("Set_CorrespondenceHistory_Response", res);
            });
    }
}