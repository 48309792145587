import { LoginApi } from "../../services/ServiceWrapper";
import { Helpers } from '../helpers';  

export const state = {
  step0Data: {
    ValidationMessage: "<strong class='inline-block mb-2'>Sorry, this username and password combination does not match our records.</strong><span>For security reasons your account will be locked after one more unsuccessful login attempts. You can reset your password by clicking on the 'Forgot Password' link below or <a href='/about-us/contact' class='text-link-md'>contact us</a>.<span>"
  },
  step0ServerResponse: {
    ValidationMessage: "<strong class='inline-block mb-2'>Sorry, this username and password combination does not match our records.</strong><span>For security reasons your account will be locked after one more unsuccessful login attempts. You can reset your password by clicking on the 'Forgot Password' link below or <a href='/about-us/contact' class='text-link-md'>contact us</a>.<span>"
  },
  step0ModalServerResponse: {}
};

export const mutations = {
  SET_LOGIN_STEP0_CONTENTS(state, dt) {
    state.step0Data = dt;
  },
  SET_LOGIN_STEP0_SERVER_RESPONSE(state, dt) {
    state.step0ServerResponse = dt;
  },
  SET_MODAL_LOGIN_STEP0_SERVER_RESPONSE(state, dt) {
    state.step0ModalServerResponse = dt;
  }
};

export const actions = {
  getLoginStep0Contents({ commit, dispatch }) {
    dispatch("setLoading", true);    
    LoginApi.GetContentStepZero()
      .then(res => {
        setTimeout(function () {
          dispatch("setLoading", false);
        }, 500);
        console.log(res);
        res.ApiHasError = false;
        commit("SET_LOGIN_STEP0_CONTENTS", res);
      })
      .catch(err => {
       
        let res = {
          ApiHasError: true,
          ValidationMessage: Helpers.getErrorResponseMessage(err),
          PageContent: {},
          FormFields: {}
        };

        console.log(res);
        commit("SET_LOGIN_STEP0_CONTENTS", res);
        dispatch("setLoading", false);
        console.log(err);
      });
  },
  submitLoginForm({ commit, dispatch }, data) {

    dispatch("setLoadingHeader", data.FormFields.ProcessingHeading);
    dispatch("setLoadingMessage", data.FormFields.ProcessingText);
    dispatch("setLoadingImage", data.FormFields.ProcessingLogoImage);
    dispatch("setLoading", true);

    LoginApi.SubmitLogin(data)
      .then(res => {
        commit("SET_LOGIN_STEP0_SERVER_RESPONSE", res);
      })
      .catch(err => {
        let res = {
          AttemptSuceeded: false,
          ValidationMessage: Helpers.getErrorResponseMessage(err)
        };

        dispatch("setLoading", false);
        commit("SET_LOGIN_STEP0_SERVER_RESPONSE", res);
      });
  },
  submitModalLoginForm({ commit, dispatch }, info) {
    var data = info.formData;
    var modalInfo = info.modalInfo;

    dispatch("setLoadingHeader", modalInfo.Header);
    dispatch("setLoadingMessage", modalInfo.Message);
    dispatch("setLoadingImage", modalInfo.Image);
    dispatch("setLoading", true);

    LoginApi.ModalSubmitLogin(data)
      .then(res => {
        dispatch("setLoading", false);
        commit("SET_MODAL_LOGIN_STEP0_SERVER_RESPONSE", res);
      })
      .catch(err => {
        let res = {
          ServerError: true,
          AttemptSuceeded: false,
          ValidationMessage: state.step0Data.ValidationMessages.ConnectOfflineDonationFormErrorMessage
        };

        dispatch("setLoading", false);
        commit("SET_MODAL_LOGIN_STEP0_SERVER_RESPONSE", res);
        console.log(err);
      });
  },
  logOutUser({ commit }, data) {
    LoginApi.LogoutUser(data)
      .then(res => {
        commit("SET_LOGOUT_SERVER_RESPONSE", res);
      })
      .catch(err => {
        commit("SET_LOGOUT_SERVER_RESPONSE", err);
        console.log(err);
      });
  }
};

export const getters = {};
