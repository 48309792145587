import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/account/login`;
// const API_URL = `https://cf9c646a-ad27-4a7f-973f-f2ecfd1c66a4.mock.pstmn.io`;
const axioService = new AxiosService(API_URL);

export var LoginApi = {
  GetContentStepZero() {
    // Get query parameter and pass to API
    let params = (new URL(document.location)).searchParams;
    let isSessionTimeout = params.get("sessiontimeout") == "true";
    let loginId = params.get("loginId");
    let isError = params.get("error") == "true";
    const url = `/loginform?sessiontimeout=${isSessionTimeout}&loginId=${loginId}&error=${isError}`;
    return axioService.get(url);
  },
  GetContentModal(params) {
    const url = `/modalloginform?datasource=${params.datasource}`;
    return axioService.get(url);
  },
  SubmitLogin(data) {
    let queryString = window.location.search;
    const url = `/loginuser` + queryString;
    return axioService.post(url, data);
  },
  ModalSubmitLogin(data) {
    const url = `/modalloginuser`;
    return axioService.post(url, data);
  },
  LogoutUser(data) {
    const url = `/logoutuser/` + data;
    return axioService.get(url);
  }
};
