import AxiosService from "./AxiosService";

const API_URL = `${process.env.VUE_APP_URL}api/account/registration`;
const axioService = new AxiosService(API_URL);

export let RegistrationApi = {

    GetContentStepZero() {
        const url = `/getcontentstepzero`;
        return axioService.get(url);
    },
    GetPortalEligability(id) {
        const url = `/getportaleligability/${id}`;
        return axioService.get(url);
    },
    GetContentStepOne(eligibility) {
        const url = `/getcontentstepone/${eligibility}`;
        return axioService.get(url);
    },
    ValidateStepOne(data) {
        let dt = data;
        const url = `/validatestepone`;

        return axioService.post(url, dt);
    },
    GetContentStepTwo() {
        const url = `/getcontentsteptwo`;
        return axioService.get(url);
    },
    ValidateUsername(userName) {
        const url = `/validateusername`;
        return axioService.postPromise(url, {
            username: userName
        });
    },
    SubmitRegistration(data) {
        const url = `/submitregistration`;
        return axioService.post(url, data);
    }
}